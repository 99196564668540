.headerSpace {
    height: 100px;
    padding: 5px 10px;
}

.header {
    position: fixed;
    background-color: white;
    top: 0;
    width: 100%;
    z-index: 2;
}

.headerContent {
    display: flex;
    padding: 5px 10px;
    height: 100px;
}

.headerLeftSpace {
    padding-left: 20px;
}

.headerTitleImage {
    height: 70%;
    margin-right: 25px
}

.headerTitle {
    font-size: 35px;
    line-height: 35px;
}

.headerTitle.pixel {
    font-size: 45px;
}

.headerSubtitle {
    font-size: 18px;
    font-style: italic;
}

.headerSubtitle.pixel {
    font-size: 20px;
}

.headerLeft {
    margin-right: auto;
    top: 0;
}
  
@media screen and (max-width: 799px) {

    .headerTitle {
        font-size: 4.375vh;
    }
    
    .headerTitle.pixel {
        font-size: 5.625vh;
    }

}