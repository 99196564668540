.dropdown {
    display: flex;
    flex-direction: row;
}

.dropdownContent {
    display: flex;
    position: absolute;
    flex-direction: column;
    background-color: rgb(235, 235, 235);
    border-radius: 20px 0 20px 20px;
    padding: 5px;
    min-width: 120px;
    right: 0;
}

.dropdownContentAnchor {
    position: relative;
    margin-left: auto;
}

.dropdownButton {
    text-align: center;
    background-color: transparent;
    padding: 10px 20px;
    left: 0;
    right: 0;
    border-radius: 20px;
}

.dropdownArrow {
    font-size: 16px;
    margin: auto 0 auto 5px;
}

.mobileDropdown {
    display: none;
}
  
@media screen and (max-width: 799px) {

    .dropdownTitle {
        display: none;
    }

    .dropdownArrow {
        display: none;
    }

    .mobileDropdown {
        display: block;
        font-size: 150%;
    }

    .dropdown {
        padding: 0 20px;
        margin-top: auto;
        margin-bottom: auto;
    }

    .dropdownContent {
        display: flex;
        position: absolute;
        flex-direction: column;
        background-color: rgb(235, 235, 235);
        border-radius: 0;
        padding: 0 0 10px 0;
        right: 0;
        left: 0;
        box-shadow: 0 5px 5px grey;
    }

    .dropdownContentAnchor {
        position: unset;
    }
    
    .dropdownButton {
        font-size: 150%;
        line-height: 200%;
        font-weight: 500;
        padding: 10px 0;
        margin-top: 10px;
        border-radius: 0;
    }
}