.cardBorder {
    border-color: lightgray;
    border-style: solid;
    border-width: 5px;
}

.cardImage {
    display: block;
    margin: 4% 0;
    border-width: 1%;
    border-style: solid;
    border-color: lightgray;
    width: 80%;
}

.cardTitle {
    margin: 5% 0 0;
    font-size: 30px;
}

.bearCardNavBar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.bearCardNav {
    font-size: 40px;
    padding: 0 8px;
}

.dot.bearCardNav {
    font-weight: bold;
}