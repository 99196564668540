/*
  FONTS
*/

@font-face {
  font-family: 'pixel';
  src: local('pixel'), url('./assets/fonts/VT323-Regular.ttf') format('truetype');
}

@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch&family=Exo+2&family=Montserrat&family=Rajdhani&family=Raleway&family=Roboto+Slab&display=swap');

body {
  margin: 0;
  font-smooth: never;
  /*font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;*/
  font-family: 'Roboto Slab';
  -webkit-font-smoothing : none;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.block {
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pixelFont {
  font-family: 'pixel';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*
  IMAGE TOOlS
*/

.pixelArt {
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}

/*
  TITLES AND TEXT SIZES
*/

.title {
  font-size: 60px;
  font-weight: lighter;
}

.title.pixel {
  font-size: 65px;
  font-weight: normal;
}

.title2 {
  font-size: 45px;
  font-weight: normal;
}

.title2.pixel {
  font-size: 55px;
  font-weight: normal;
}

.smallTitle {
  font-size: 30px;
  font-weight: bold;
}

.smallTitle.pixel {
  font-size: 45px;
  font-weight: normal;
}

.subtitle {
  font-size: 25px;
  font-style: italic;
}

.subtitle.pixel {
  font-size: 30px;
}

.textContent {
  font-size: 19px;
  line-height: 32px;
}

.textContent.pixel {
  font-size: 24px;
}

.smallText {
  font-size: 16px;
}

.smallText.pixel {
  font-size: 22px;
}

.mobileOnly {
  display: none;
}

/* Take what it was before divide by 5 and change to vw */
@media screen and (max-width: 799px) {

  .title {
    font-size: 12vh;
    font-weight: lighter;
  }
  
  .title.pixel {
    font-size: 13vh;
    font-weight: normal;
  }
  
  .title2 {
    font-size: 6vh;
    font-weight: normal;
  }
  
  .title2.pixel {
    font-size: 9vh;
    font-weight: normal;
  }
  
  .smallTitle {
    font-size: 6vh;
    font-weight: bold;
  }
  
  .smallTitle.pixel {
    font-size: 9vh;
    font-weight: normal;
  }
  
  .textContent {
    font-size: 2.4vh;
    line-height: 4vh;
  }
  
  .textContent.pixel {
    font-size: 3vh;
  }

  .smallText {
    font-size: 2vh;
  }
  
  .smallText.pixel {
    font-size: 2.75vh;
  }

  .mobileOnly {
    display: block;
  }
  
}

/*
  GENERAL SPACING/PLACEMENT/SIZING TOOLS
*/

.flexResize {
  flex-grow: 1;
  flex-shrink: 1;
}

.flexCenterVertically {
  display: flex;
  align-items: center;
}


.centeredVertically {
  margin-top: auto;
  margin-bottom: auto;
}

.centeredHorizontally {
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexRowSwitch{
  display: flex;
  flex-direction: row;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.equalRowSpacing {
  margin-left: auto;
  margin-right: auto;
}

.padded {
  padding-bottom: 20px;
}

.imageBarContent.padded {
  padding-top: 20px;
}

.titleBar > .padded {
  padding-top: 60px;
  padding-bottom: 60px;
}

.paragraph {
  margin-top: 40px;
  margin-bottom: 40px;
}

.titleSpace {
  margin-bottom: 30px;
}

.skinnyContent {
  padding-left: 20vw;
  padding-right: 20vw;
}

.wideContent {
  padding-left: 10vw;
  padding-right: 10vw;
}

.headerFooterContent {
  padding-left: 14vw;
  padding-right: 14vw;
}

@media screen and (min-width: 1300px) {
  
  .paragraph {
    width: 70%;
    min-width: 500px;
  }

}

@media screen and (max-width: 1299px) and (min-width: 800px)  {

  .skinnyContent {
    padding-left: 10vw;
    padding-right: 10vw;
  }

  .wideContent {
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .headerFooterContent {
    padding-left: 7vw;
    padding-right: 7vw;
  }
  
  .paragraph {
    min-width: 500px;
    text-align: center;
  }

}

@media screen and (max-width: 799px) {

  .skinnyContent {
    padding-left: 4vw;
    padding-right: 4vw;
  }

  .wideContent {
    padding-left: 4vw;
    padding-right: 4vw;
  }

  .headerFooterContent {
    padding-left: 4vw;
    padding-right: 4vw;
  }

  .paragraph {
    text-align: center;
  }

  .header {
    background-color: rgb(244, 244, 244);
  }
  
  .flexRowSwitch{
    flex-direction: column
  }
}

/*
  GENERAL TEXT TOOLS
*/

.whiteText {
  color: white;
}

.rightTextAlign {
  text-align: right;
}

.centerTextAlign {
  text-align: center;
}

/*
  LINKS, LOGOS, AND BUTTONS
*/

.button {
  width: fit-content;
  padding: 10px;
  border-radius: 5px;
  border-width: 2px;
  border-color: white;
  background-color: transparent;
  border-style: solid;
}

.button.blue {
  border-color: #07254a;
  color: #07254a;
}

.button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.button.blue:hover {
  background-color: #07254a44;
}


.logo {
  -webkit-mask-size: contain;
  mask-size: contain;
  background-color: black;
  width: 30px;
  height: 30px;
}

.logo.small {
  width: 25px;
  height: 25px;
}

.pointer {
  cursor: pointer;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.noLinkStyling {
  text-decoration: none;
  color: #000;
}


/* 
  PAGE TITLE 
*/

.pageTitle {
  font-size: 3.3vw;
  font-weight: lighter;
  padding-bottom: 3%;
}

.pageTitleText {
  font-size: 1.1vw;
  line-height: 2vw;
}

.pageTitleBar {
  padding-top: min(5vh, 5vw);
  padding-bottom: min(5vh, 5vw);
  display: flex;
}

@media screen and (min-width: 1300px) {

  .line {
    width: 1px;
    background-color: black;
    margin: 15px 0;
  }
  
  .pageTitleBar {
    flex-direction: row;
    margin-top: 20px;
  }

  .pageTitleText {
    margin-left: 20px;
  }

  .pageTitle {
    margin-right: 20px;
  }
  
}

@media screen and (max-width: 1299px) and (min-width: 800px) {

  .line {
    width: 0px;
    background-color: black;
    margin: 0;
  }

  .pageTitle {
    font-size: 70px;
    text-align: center;
  }
  
  .pageTitleBar {
    flex-direction: column;
  }

  .pageTitleText {
    font-size: 20px;
    line-height: 33px;
    padding-top: 40px;
  }
  
}

@media screen and (max-width: 799px) {

  .line {
    width: 0px;
    background-color: black;
    margin: 0;
  }

  .pageTitle {
    font-size: min(7vh, 12vw);
    text-align: center;
  }
  
  .pageTitleBar {
    flex-direction: column;
  }

  .pageTitleText {
    font-size: 2.8vh;
    line-height: 4.4vh;
    padding-top: 20px;
  }
  
}


/*
  TITLE BAR
*/

.darkGray {
  background-color: #dddddd;
}

.lightGray {
  background-color: #eeeeee;
}

.bar {
  padding-top: 30px;
  padding-bottom: 30px;
}

/*
  FIXED TITLE BAR
*/

.fixedTitleBar {
  background-color: #eeeeee;
  height: 250px;
  width: 100%;
  margin-top: 20px;
}

.fixedTitleBarImage {
  height: 100%; 
  float: right;
  padding: 0 20vw 0 20vw;
  margin-left: 100% 
}

.fixedTitleBarAnchor {
  position: relative;
  top: 0px;
  height: 100%;
}

.fixedTitleBarMainText {
  position: absolute;
  top: 80px;
}

.fixedTitleBarSubText {
  position: absolute;
  top: 140px;
}

canvas {
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-crisp-edges;
  image-rendering: pixelated;
  image-rendering: crisp-edges;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.noScrollBar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.noScrollBar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.popUp {
  z-index: 100;
}

/*
  IMAGE BAR
*/

.titleBar {
  background-color: #eeeeee;
  display: flex;
  align-items: center;
}

.darkOverlay {
  position: relative;
}

.grayOverlay {
  position: relative;
}

.projectBar {
  min-height: 240px;
}

.imageBar {
  height: 400px;
  width: 100%;
  margin-top: 50px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.barBackground {
    background-size: contain;
    background-repeat: no-repeat;
}

.barBackground.right {
  background-position: right;
}

.barContent.full {
  width: 100%;
}

.barContent.left {
  margin-right: auto;
  width: 50%;
}

.barContent.right {
  margin-left: auto;
  width: 50%;
}

.aboveOverlay {
    z-index: 1;
    position: relative;
}

@media screen and (max-width: 1500px) and (min-width: 1000px) {

    .darkOverlay.left::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 35%, rgba(0,0,0,0.3) 52%, rgba(0,0,0,0.3) 100%);
    }

    .darkOverlay.right::before {
      content: "";
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 35%, rgba(0,0,0,0.3) 52%, rgba(0,0,0,0.3) 100%);
    }

    .grayOverlay.left::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 35%, rgba(165, 165, 165, 0.4) 52%, rgba(165, 165, 165, 0.4) 100%);
    }

    .grayOverlay.right::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 35%, rgba(165, 165, 165, 0.4) 52%, rgba(165, 165, 165, 0.4) 100%);
    }
  
}

@media screen and (max-width: 999px) {

    .darkOverlay::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-color: rgba(0,0,0,0.3);
    }

    .grayOverlay::before {
      content: "";
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      background-color: rgba(165, 165, 165, 0.45);
    }

    .barContent.left, .barContent.right, .barContent.full {
        width: 100%;
        margin-left: 0;
        min-height: 0;
    }

    .projectBar {
      min-height: 0px;
    }

    .wideBackgroundImage {
      background-size: cover;
      background-position: center;
    }
  
}