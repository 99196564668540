.modal {
    background-color: white;
    display: flex;
    flex-direction: row;
    position: fixed;
    width: 70%;
    height: 70%;
    top: 15%;
    left: 15%;
    border-radius: 20px;
}

#bearVariantModal {
    left: 95%;
}

#bearInfoModalLeft {
    min-width: fit-content;
    padding: 24px 14px 24px 50px;
}

#modalBearWiki {
    width: 100%;
    height: 100%;
}

#bearInfoModalRight {
    padding: 48px;  
    overflow-y: auto;
    font-size: 24px;
}


#bearInfoModalRight::-webkit-scrollbar {
    background-color: transparent;
    width: 15px;
}

#bearInfoModalRight::-webkit-scrollbar-button {
    background-color: transparent;
    height: 10px;
    width: 10px;
}


#bearInfoModalRight::-webkit-scrollbar-track {
    background-color: transparent;
}


#bearInfoModalRight::-webkit-scrollbar-track-piece {
    background-color: transparent;
}


#bearInfoModalRight::-webkit-scrollbar-thumb {
    background-color: lightgray;
    border-radius: 10px;
}

.modalItem > .cardTitle {
    padding-bottom: 30px;
}

.blackOverlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
}

.modalItem > .cardImage {
    height: 40vh;
    width: 40vh;
}

@media screen and (max-width: 1350px) {

    .modal {
        flex-direction: column;
        overflow-y: auto;
    }

    #bearInfoModalLeft {
        display: flex;
        padding: 24px;
        justify-content: center;
    }
    
    #bearInfoModalRight {
        overflow-y: unset;
    }

    .modalItem > .cardTitle {
        text-align: center;
    }

    
    .modal::-webkit-scrollbar {
        background-color: transparent;
        width: 15px;
    }

    .modal::-webkit-scrollbar-button {
        background-color: transparent;
        height: 10px;
        width: 10px;
    }


    .modal::-webkit-scrollbar-track {
        background-color: transparent;
    }


    .modal::-webkit-scrollbar-track-piece {
        background-color: transparent;
    }


    .modal::-webkit-scrollbar-thumb {
        background-color: lightgray;
        border-radius: 10px;
    }
    
}

@media screen and (max-width: 799px) {

    .modal {
        width: 90%;
        height: 90%;
        top: 5%;
        left: 5%;
    }

}