
/*Timeline */ 
.timeline {
    font-size: 1em;
    line-height: 1.75em;
    border-top: 3px solid;
    border-image: linear-gradient(to right, #3ad5d0 0%, #3aaed5 100%);
    border-image-slice: 1;
    border-width: 3px;
    margin: 0;
    padding: 40px;
    counter-increment: section;
    position: relative;
    color: #34435E;
  }
  
  /*numbers*/
  .timeline:before {
      content: counter(section);
      position: absolute;
      border-radius: 50%;
      padding: 10px;
      height: 1.25em;
      width: 1.25em;
      background-color: #3a8ad5;
      text-align: center;
      line-height: 1.25em;
      color: white;
      font-size: 1em;
  }
  
  /*odd number borders*/
  .timeline:nth-child(odd) {
    border-left: 0px;
    border-right: 3px solid;
    border-top: 0px;
    border-bottom: 3px solid;
    padding-left: 0;
    
  }
  .timeline:nth-child(odd):before {
      left: 100%; 
      margin-left: -20px;
  }
    
  /*even number borders*/
  .timeline:nth-child(even) {
    border-left: 3px solid;
    border-right: 0px;
    border-top: 0px;
    border-bottom: 3px solid;
    padding-right: 0;
  }  
  .timeline:nth-child(even):before {
      right: 100%; 
      margin-right: -20px;
  }
  
  /*handle first and last*/
  .timeline:first-child {
    border-top: 0px;
    border-left: 0px; 
    border-top-right-radius:0;
    border-top-left-radius:0;
  }
  .timeline:last-child {
    border-bottom-right-radius:0;
    border-bottom-left-radius:0;
  }
  
  .timelineTop {
    left: -4px;
    right: 0;
    top: 0;
    bottom: -85px;
    z-index: -200;
    position: absolute;
    border-top: 3px solid;
    border-right: 3px solid;
    border-bottom: 0px;
    border-left: 0px;
    border-image: linear-gradient(to right, #000000 0%, #3aaed5 100%);
    border-image-slice: 1;
  }