.linkHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 20px;
}

.linkHolder.small {
    margin: 5px 15px;
}

.linkText {
    font-size: 18px;
    line-height: 32px;
    font-weight: 500;
}

.linkText.small {
    font-size: 14px;
    line-height: 26px;
}

.linkText.pixel {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
}

.linkText.small.pixel {
    font-size: 19px;
    line-height: 26px;
}

@media screen and (max-width: 799px) {

    .linkHolder {
        display: none;
    }

    .linkHolder.small {
        display: block;
    }

    .linkHolder.mobile {
        display: flex;
        margin: 0;
    }

}