.halfWidth {
    width: 50%;
}

.imageScroll {
    max-width: 45vh;
    margin-right: 50px;
}

.walletButton {
    margin: 20px;
    width: 150px;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.flexRow.dispenser {
    justify-content: center;
}

@media screen and (max-width: 799px) {

    .card2 {
        margin-top: 40px;
    }


    .halfWidth {
        width: unset;
    }

    .imageScroll {
        width: 80%;
        margin-top: 40px;
        margin-right: 0;
    }

}