
.topoOptGIF {
    margin: 3%;
    min-width: 30%;
}

.centerText {
    align-items: center;
}

.gifRow {
    display: flex;
    flex-direction: row;
}

.topo.button {
    margin: 15px 5px;
}


@media screen and (max-width: 1299px) {
    .gifRow {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .topoOptGIF {
        min-width: 70%;
    }

    .topo.button {
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
    }

}