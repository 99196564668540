@import url(https://fonts.googleapis.com/css2?family=Chakra+Petch&family=Exo+2&family=Montserrat&family=Rajdhani&family=Raleway&family=Roboto+Slab&display=swap);
/*
  FONTS
*/

@font-face {
  font-family: 'pixel';
  src: local('pixel'), url(/static/media/VT323-Regular.1d510e1c.ttf) format('truetype');
}

body {
  margin: 0;
  font-smooth: never;
  /*font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;*/
  font-family: 'Roboto Slab';
  -webkit-font-smoothing : none;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.block {
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pixelFont {
  font-family: 'pixel';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*
  IMAGE TOOlS
*/

.pixelArt {
  -ms-interpolation-mode: nearest-neighbor;
      image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}

/*
  TITLES AND TEXT SIZES
*/

.title {
  font-size: 60px;
  font-weight: lighter;
}

.title.pixel {
  font-size: 65px;
  font-weight: normal;
}

.title2 {
  font-size: 45px;
  font-weight: normal;
}

.title2.pixel {
  font-size: 55px;
  font-weight: normal;
}

.smallTitle {
  font-size: 30px;
  font-weight: bold;
}

.smallTitle.pixel {
  font-size: 45px;
  font-weight: normal;
}

.subtitle {
  font-size: 25px;
  font-style: italic;
}

.subtitle.pixel {
  font-size: 30px;
}

.textContent {
  font-size: 19px;
  line-height: 32px;
}

.textContent.pixel {
  font-size: 24px;
}

.smallText {
  font-size: 16px;
}

.smallText.pixel {
  font-size: 22px;
}

.mobileOnly {
  display: none;
}

/* Take what it was before divide by 5 and change to vw */
@media screen and (max-width: 799px) {

  .title {
    font-size: 12vh;
    font-weight: lighter;
  }
  
  .title.pixel {
    font-size: 13vh;
    font-weight: normal;
  }
  
  .title2 {
    font-size: 6vh;
    font-weight: normal;
  }
  
  .title2.pixel {
    font-size: 9vh;
    font-weight: normal;
  }
  
  .smallTitle {
    font-size: 6vh;
    font-weight: bold;
  }
  
  .smallTitle.pixel {
    font-size: 9vh;
    font-weight: normal;
  }
  
  .textContent {
    font-size: 2.4vh;
    line-height: 4vh;
  }
  
  .textContent.pixel {
    font-size: 3vh;
  }

  .smallText {
    font-size: 2vh;
  }
  
  .smallText.pixel {
    font-size: 2.75vh;
  }

  .mobileOnly {
    display: block;
  }
  
}

/*
  GENERAL SPACING/PLACEMENT/SIZING TOOLS
*/

.flexResize {
  flex-grow: 1;
  flex-shrink: 1;
}

.flexCenterVertically {
  display: flex;
  align-items: center;
}


.centeredVertically {
  margin-top: auto;
  margin-bottom: auto;
}

.centeredHorizontally {
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexRowSwitch{
  display: flex;
  flex-direction: row;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.equalRowSpacing {
  margin-left: auto;
  margin-right: auto;
}

.padded {
  padding-bottom: 20px;
}

.imageBarContent.padded {
  padding-top: 20px;
}

.titleBar > .padded {
  padding-top: 60px;
  padding-bottom: 60px;
}

.paragraph {
  margin-top: 40px;
  margin-bottom: 40px;
}

.titleSpace {
  margin-bottom: 30px;
}

.skinnyContent {
  padding-left: 20vw;
  padding-right: 20vw;
}

.wideContent {
  padding-left: 10vw;
  padding-right: 10vw;
}

.headerFooterContent {
  padding-left: 14vw;
  padding-right: 14vw;
}

@media screen and (min-width: 1300px) {
  
  .paragraph {
    width: 70%;
    min-width: 500px;
  }

}

@media screen and (max-width: 1299px) and (min-width: 800px)  {

  .skinnyContent {
    padding-left: 10vw;
    padding-right: 10vw;
  }

  .wideContent {
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .headerFooterContent {
    padding-left: 7vw;
    padding-right: 7vw;
  }
  
  .paragraph {
    min-width: 500px;
    text-align: center;
  }

}

@media screen and (max-width: 799px) {

  .skinnyContent {
    padding-left: 4vw;
    padding-right: 4vw;
  }

  .wideContent {
    padding-left: 4vw;
    padding-right: 4vw;
  }

  .headerFooterContent {
    padding-left: 4vw;
    padding-right: 4vw;
  }

  .paragraph {
    text-align: center;
  }

  .header {
    background-color: rgb(244, 244, 244);
  }
  
  .flexRowSwitch{
    flex-direction: column
  }
}

/*
  GENERAL TEXT TOOLS
*/

.whiteText {
  color: white;
}

.rightTextAlign {
  text-align: right;
}

.centerTextAlign {
  text-align: center;
}

/*
  LINKS, LOGOS, AND BUTTONS
*/

.button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  border-radius: 5px;
  border-width: 2px;
  border-color: white;
  background-color: transparent;
  border-style: solid;
}

.button.blue {
  border-color: #07254a;
  color: #07254a;
}

.button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.button.blue:hover {
  background-color: #07254a44;
}


.logo {
  -webkit-mask-size: contain;
  mask-size: contain;
  background-color: black;
  width: 30px;
  height: 30px;
}

.logo.small {
  width: 25px;
  height: 25px;
}

.pointer {
  cursor: pointer;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.noLinkStyling {
  text-decoration: none;
  color: #000;
}


/* 
  PAGE TITLE 
*/

.pageTitle {
  font-size: 3.3vw;
  font-weight: lighter;
  padding-bottom: 3%;
}

.pageTitleText {
  font-size: 1.1vw;
  line-height: 2vw;
}

.pageTitleBar {
  padding-top: min(5vh, 5vw);
  padding-bottom: min(5vh, 5vw);
  display: flex;
}

@media screen and (min-width: 1300px) {

  .line {
    width: 1px;
    background-color: black;
    margin: 15px 0;
  }
  
  .pageTitleBar {
    flex-direction: row;
    margin-top: 20px;
  }

  .pageTitleText {
    margin-left: 20px;
  }

  .pageTitle {
    margin-right: 20px;
  }
  
}

@media screen and (max-width: 1299px) and (min-width: 800px) {

  .line {
    width: 0px;
    background-color: black;
    margin: 0;
  }

  .pageTitle {
    font-size: 70px;
    text-align: center;
  }
  
  .pageTitleBar {
    flex-direction: column;
  }

  .pageTitleText {
    font-size: 20px;
    line-height: 33px;
    padding-top: 40px;
  }
  
}

@media screen and (max-width: 799px) {

  .line {
    width: 0px;
    background-color: black;
    margin: 0;
  }

  .pageTitle {
    font-size: min(7vh, 12vw);
    text-align: center;
  }
  
  .pageTitleBar {
    flex-direction: column;
  }

  .pageTitleText {
    font-size: 2.8vh;
    line-height: 4.4vh;
    padding-top: 20px;
  }
  
}


/*
  TITLE BAR
*/

.darkGray {
  background-color: #dddddd;
}

.lightGray {
  background-color: #eeeeee;
}

.bar {
  padding-top: 30px;
  padding-bottom: 30px;
}

/*
  FIXED TITLE BAR
*/

.fixedTitleBar {
  background-color: #eeeeee;
  height: 250px;
  width: 100%;
  margin-top: 20px;
}

.fixedTitleBarImage {
  height: 100%; 
  float: right;
  padding: 0 20vw 0 20vw;
  margin-left: 100% 
}

.fixedTitleBarAnchor {
  position: relative;
  top: 0px;
  height: 100%;
}

.fixedTitleBarMainText {
  position: absolute;
  top: 80px;
}

.fixedTitleBarSubText {
  position: absolute;
  top: 140px;
}

canvas {
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
      image-rendering: pixelated;
  image-rendering: crisp-edges;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.noScrollBar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.noScrollBar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.popUp {
  z-index: 100;
}

/*
  IMAGE BAR
*/

.titleBar {
  background-color: #eeeeee;
  display: flex;
  align-items: center;
}

.darkOverlay {
  position: relative;
}

.grayOverlay {
  position: relative;
}

.projectBar {
  min-height: 240px;
}

.imageBar {
  height: 400px;
  width: 100%;
  margin-top: 50px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.barBackground {
    background-size: contain;
    background-repeat: no-repeat;
}

.barBackground.right {
  background-position: right;
}

.barContent.full {
  width: 100%;
}

.barContent.left {
  margin-right: auto;
  width: 50%;
}

.barContent.right {
  margin-left: auto;
  width: 50%;
}

.aboveOverlay {
    z-index: 1;
    position: relative;
}

@media screen and (max-width: 1500px) and (min-width: 1000px) {

    .darkOverlay.left::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 35%, rgba(0,0,0,0.3) 52%, rgba(0,0,0,0.3) 100%);
    }

    .darkOverlay.right::before {
      content: "";
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 35%, rgba(0,0,0,0.3) 52%, rgba(0,0,0,0.3) 100%);
    }

    .grayOverlay.left::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 35%, rgba(165, 165, 165, 0.4) 52%, rgba(165, 165, 165, 0.4) 100%);
    }

    .grayOverlay.right::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 35%, rgba(165, 165, 165, 0.4) 52%, rgba(165, 165, 165, 0.4) 100%);
    }
  
}

@media screen and (max-width: 999px) {

    .darkOverlay::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-color: rgba(0,0,0,0.3);
    }

    .grayOverlay::before {
      content: "";
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      background-color: rgba(165, 165, 165, 0.45);
    }

    .barContent.left, .barContent.right, .barContent.full {
        width: 100%;
        margin-left: 0;
        min-height: 0;
    }

    .projectBar {
      min-height: 0px;
    }

    .wideBackgroundImage {
      background-size: cover;
      background-position: center;
    }
  
}
.linkHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 20px;
}

.linkHolder.small {
    margin: 5px 15px;
}

.linkText {
    font-size: 18px;
    line-height: 32px;
    font-weight: 500;
}

.linkText.small {
    font-size: 14px;
    line-height: 26px;
}

.linkText.pixel {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
}

.linkText.small.pixel {
    font-size: 19px;
    line-height: 26px;
}

@media screen and (max-width: 799px) {

    .linkHolder {
        display: none;
    }

    .linkHolder.small {
        display: block;
    }

    .linkHolder.mobile {
        display: flex;
        margin: 0;
    }

}
.footerBar {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: lightgray;
}
.headerSpace {
    height: 100px;
    padding: 5px 10px;
}

.header {
    position: fixed;
    background-color: white;
    top: 0;
    width: 100%;
    z-index: 2;
}

.headerContent {
    display: flex;
    padding: 5px 10px;
    height: 100px;
}

.headerLeftSpace {
    padding-left: 20px;
}

.headerTitleImage {
    height: 70%;
    margin-right: 25px
}

.headerTitle {
    font-size: 35px;
    line-height: 35px;
}

.headerTitle.pixel {
    font-size: 45px;
}

.headerSubtitle {
    font-size: 18px;
    font-style: italic;
}

.headerSubtitle.pixel {
    font-size: 20px;
}

.headerLeft {
    margin-right: auto;
    top: 0;
}
  
@media screen and (max-width: 799px) {

    .headerTitle {
        font-size: 4.375vh;
    }
    
    .headerTitle.pixel {
        font-size: 5.625vh;
    }

}

.dropdown {
    display: flex;
    flex-direction: row;
}

.dropdownContent {
    display: flex;
    position: absolute;
    flex-direction: column;
    background-color: rgb(235, 235, 235);
    border-radius: 20px 0 20px 20px;
    padding: 5px;
    min-width: 120px;
    right: 0;
}

.dropdownContentAnchor {
    position: relative;
    margin-left: auto;
}

.dropdownButton {
    text-align: center;
    background-color: transparent;
    padding: 10px 20px;
    left: 0;
    right: 0;
    border-radius: 20px;
}

.dropdownArrow {
    font-size: 16px;
    margin: auto 0 auto 5px;
}

.mobileDropdown {
    display: none;
}
  
@media screen and (max-width: 799px) {

    .dropdownTitle {
        display: none;
    }

    .dropdownArrow {
        display: none;
    }

    .mobileDropdown {
        display: block;
        font-size: 150%;
    }

    .dropdown {
        padding: 0 20px;
        margin-top: auto;
        margin-bottom: auto;
    }

    .dropdownContent {
        display: flex;
        position: absolute;
        flex-direction: column;
        background-color: rgb(235, 235, 235);
        border-radius: 0;
        padding: 0 0 10px 0;
        right: 0;
        left: 0;
        box-shadow: 0 5px 5px grey;
    }

    .dropdownContentAnchor {
        position: unset;
    }
    
    .dropdownButton {
        font-size: 150%;
        line-height: 200%;
        font-weight: 500;
        padding: 10px 0;
        margin-top: 10px;
        border-radius: 0;
    }
}

.modal {
    background-color: white;
    display: flex;
    flex-direction: row;
    position: fixed;
    width: 70%;
    height: 70%;
    top: 15%;
    left: 15%;
    border-radius: 20px;
}

#bearVariantModal {
    left: 95%;
}

#bearInfoModalLeft {
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    padding: 24px 14px 24px 50px;
}

#modalBearWiki {
    width: 100%;
    height: 100%;
}

#bearInfoModalRight {
    padding: 48px;  
    overflow-y: auto;
    font-size: 24px;
}


#bearInfoModalRight::-webkit-scrollbar {
    background-color: transparent;
    width: 15px;
}

#bearInfoModalRight::-webkit-scrollbar-button {
    background-color: transparent;
    height: 10px;
    width: 10px;
}


#bearInfoModalRight::-webkit-scrollbar-track {
    background-color: transparent;
}


#bearInfoModalRight::-webkit-scrollbar-track-piece {
    background-color: transparent;
}


#bearInfoModalRight::-webkit-scrollbar-thumb {
    background-color: lightgray;
    border-radius: 10px;
}

.modalItem > .cardTitle {
    padding-bottom: 30px;
}

.blackOverlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
}

.modalItem > .cardImage {
    height: 40vh;
    width: 40vh;
}

@media screen and (max-width: 1350px) {

    .modal {
        flex-direction: column;
        overflow-y: auto;
    }

    #bearInfoModalLeft {
        display: flex;
        padding: 24px;
        justify-content: center;
    }
    
    #bearInfoModalRight {
        overflow-y: unset;
    }

    .modalItem > .cardTitle {
        text-align: center;
    }

    
    .modal::-webkit-scrollbar {
        background-color: transparent;
        width: 15px;
    }

    .modal::-webkit-scrollbar-button {
        background-color: transparent;
        height: 10px;
        width: 10px;
    }


    .modal::-webkit-scrollbar-track {
        background-color: transparent;
    }


    .modal::-webkit-scrollbar-track-piece {
        background-color: transparent;
    }


    .modal::-webkit-scrollbar-thumb {
        background-color: lightgray;
        border-radius: 10px;
    }
    
}

@media screen and (max-width: 799px) {

    .modal {
        width: 90%;
        height: 90%;
        top: 5%;
        left: 5%;
    }

}
.cardBorder {
    border-color: lightgray;
    border-style: solid;
    border-width: 5px;
}

.cardImage {
    display: block;
    margin: 4% 0;
    border-width: 1%;
    border-style: solid;
    border-color: lightgray;
    width: 80%;
}

.cardTitle {
    margin: 5% 0 0;
    font-size: 30px;
}

.bearCardNavBar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.bearCardNav {
    font-size: 40px;
    padding: 0 8px;
}

.dot.bearCardNav {
    font-weight: bold;
}

.topoOptGIF {
    margin: 3%;
    min-width: 30%;
}

.centerText {
    align-items: center;
}

.gifRow {
    display: flex;
    flex-direction: row;
}

.topo.button {
    margin: 15px 5px;
}


@media screen and (max-width: 1299px) {
    .gifRow {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .topoOptGIF {
        min-width: 70%;
    }

    .topo.button {
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
    }

}

/*Timeline */ 
.timeline {
    font-size: 1em;
    line-height: 1.75em;
    border-top: 3px solid;
    border-image: linear-gradient(to right, #3ad5d0 0%, #3aaed5 100%);
    border-image-slice: 1;
    border-width: 3px;
    margin: 0;
    padding: 40px;
    counter-increment: section;
    position: relative;
    color: #34435E;
  }
  
  /*numbers*/
  .timeline:before {
      content: counter(section);
      position: absolute;
      border-radius: 50%;
      padding: 10px;
      height: 1.25em;
      width: 1.25em;
      background-color: #3a8ad5;
      text-align: center;
      line-height: 1.25em;
      color: white;
      font-size: 1em;
  }
  
  /*odd number borders*/
  .timeline:nth-child(odd) {
    border-left: 0px;
    border-right: 3px solid;
    border-top: 0px;
    border-bottom: 3px solid;
    padding-left: 0;
    
  }
  .timeline:nth-child(odd):before {
      left: 100%; 
      margin-left: -20px;
  }
    
  /*even number borders*/
  .timeline:nth-child(even) {
    border-left: 3px solid;
    border-right: 0px;
    border-top: 0px;
    border-bottom: 3px solid;
    padding-right: 0;
  }  
  .timeline:nth-child(even):before {
      right: 100%; 
      margin-right: -20px;
  }
  
  /*handle first and last*/
  .timeline:first-child {
    border-top: 0px;
    border-left: 0px; 
    border-top-right-radius:0;
    border-top-left-radius:0;
  }
  .timeline:last-child {
    border-bottom-right-radius:0;
    border-bottom-left-radius:0;
  }
  
  .timelineTop {
    left: -4px;
    right: 0;
    top: 0;
    bottom: -85px;
    z-index: -200;
    position: absolute;
    border-top: 3px solid;
    border-right: 3px solid;
    border-bottom: 0px;
    border-left: 0px;
    border-image: linear-gradient(to right, #000000 0%, #3aaed5 100%);
    border-image-slice: 1;
  }
.centerText {
    align-items: center;
}

.gifRow {
    display: flex;
    flex-direction: row;
}

.topo.button {
    margin: 15px 5px;
}

.paragraph.topo {
    width: 90%;
}


@media screen and (max-width: 1299px) {
    .gifRow {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .topoOptGIF {
        min-width: 70%;
        margin-bottom: 40px;
    }

    .topo.button {
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
    }

}
.halfWidth {
    width: 50%;
}

.imageScroll {
    max-width: 45vh;
    margin-right: 50px;
}

.walletButton {
    margin: 20px;
    width: 150px;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.flexRow.dispenser {
    justify-content: center;
}

@media screen and (max-width: 799px) {

    .card2 {
        margin-top: 40px;
    }


    .halfWidth {
        width: unset;
    }

    .imageScroll {
        width: 80%;
        margin-top: 40px;
        margin-right: 0;
    }

}
.card {
    border-radius: 20px;
    border-width: 2px;
    border-color: lightgray;
    border-style: solid;
    background-color: white;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
}

.cardImage {
    display: block;
    margin: 4% 0;
    border-width: 1%;
    border-style: solid;
    border-color: lightgray;
    width: 80%;
}

.cardTitle {
    margin: 0 0 5%;
    font-size: 30px;
}

.bearCardNavBar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.bearCardNav {
    font-size: 40px;
    padding: 0 8px;
}

.dot.bearCardNav {
    font-weight: bold;
}

.arrow {
    display:inline-block;
    -webkit-transform:scale(1,2); /* Safari and Chrome */ /* Firefox */ /* IE 9 */ /* Opera */
    transform:scale(1,2); /* W3C */
}
.transition {
    height: 100px;
    width: 100%;
}
