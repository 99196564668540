.card {
    border-radius: 20px;
    border-width: 2px;
    border-color: lightgray;
    border-style: solid;
    background-color: white;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
}

.cardImage {
    display: block;
    margin: 4% 0;
    border-width: 1%;
    border-style: solid;
    border-color: lightgray;
    width: 80%;
}

.cardTitle {
    margin: 0 0 5%;
    font-size: 30px;
}

.bearCardNavBar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.bearCardNav {
    font-size: 40px;
    padding: 0 8px;
}

.dot.bearCardNav {
    font-weight: bold;
}

.arrow {
    display:inline-block;
    -webkit-transform:scale(1,2); /* Safari and Chrome */
    -moz-transform:scale(1,2); /* Firefox */
    -ms-transform:scale(1,2); /* IE 9 */
    -o-transform:scale(1,2); /* Opera */
    transform:scale(1,2); /* W3C */
}